$(document).ready(function () {
    var url = window.location.href;
    if (url.includes("contacto")) {
        setTimeout(
            function () {
                var map = new Microsoft.Maps.Map(document.getElementById('myMap'), {
                    credentials: 'Arv-AqfAlZS_tNSTmPjHByEvLJuWcnZpMXorPFgbr2uE1cmiayoFp_rOZm9ltIdd',
                    mapTypeId: Microsoft.Maps.MapTypeId.aerial,
                    zoom: 20
                });
                var loc = new Microsoft.Maps.Location(
                    '28.0032155',
                    '-15.3893186,3a,75y');

                var pin = new Microsoft.Maps.Pushpin(loc, {
                    title: 'VySA',
                    subTitle: 'Pasaje de Tetuán, portal 13, puerta 1º H',
                    text: ''
                });
                map.entities.push(pin);
                map.setView({ center: loc, zoom: 18, credentials: 'Arv-AqfAlZS_tNSTmPjHByEvLJuWcnZpMXorPFgbr2uE1cmiayoFp_rOZm9ltIdd' });
            }, 1000);
    }
});