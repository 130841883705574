frases = {
    "de": {
        "menuInicio": "Anfang",
        "menuNosotros": "Über Uns",
        "menuContacto": "Kontakt",
        "menuServicios": "Dienstleistungen",
        "menuGestionProcesos": "Prozessmanagement",
        "menuGestionProyectos": "Projektmanagement",
        "menuFinanciero": "Finanzen",
        "menuPersonal": "Personal",
        "menuInformatica": "I.T",
        "menuCostes": "Einkauf",
        "linea1EncabezadoPrincipal": "Steuerberatung und Wirtschaftsprüfung",
        "linea2EncabezadoPrincipal": "Business Management",
        "tituloContenidoPrincipal": "Anfang",
        "parrafo1ContenidoPrincipal": "Vielen Dank dafür, dass Sie unsere neue Webseite besuchen. Hier können Sie finden Informationen, die Sie über unser Unternehmen benötigen und die Dienstleistungen , die wir Ihnen anbieten.",
        "parrafo2ContenidoPrincipal": "In addition , Darüber hinaus ist unser Team auf der Hand, jederzeit auf jedem Aspekt unseres Geschäfts Sie zu beraten.",
        "parrafo3ContenidoPrincipal": "Kontaktieren Sie mit uns, wir werden Ihnen Jederzeit gerne Helfen.",
        "tituloContenidoNosotros": "Über Uns",
        "parrafo1ContenidoNosotros": "ALS STARKER PARTNER FÜR DEN CFO ...",
        "parrafo2ContenidoNosotros": "… …bietet die Villa & Seco Asesores eine umfassende Beratungsleistung in den Bereichen Finanzen, IT, Personal und Einkauf an. Dabei verfolgen wir einen ganzheitlichen Beratungsansatz, der darauf abzielt, gemeinsam mit Ihnen Lösungen zu entwickeln und deren Umsetzung aktiv zu begleiten. Wir zeichnen uns dadurch aus, dass wir für Sie nicht nur Handlungsempfehlungen entwickeln, sondern Sie auch bei der Implementierung unterstützen. Hierzu stehen Ihnen zur Unterstützung in allen Stufen des Projekts unsere erfahrenen Berater zur Seite. Unsere Arbeit zeichnet uns vor allem durch die Verknüpfung des Knowhows aus mehreren Fachgebieten aus. So bieten wir Ihnen eine Kombination von Expertise in den Bereichen Finanzen und IT, die wir in zahlreichen Projekten zur Systemeinführung gesammelt haben. Neben der Einführung und Optimierung von IT-Tools und weiterführenden IT-Systemen bieten wir Ihnen auch die Entwicklung und Neuausrichtungen einzelner Abteilungen mittels IT-Unterstützung an. Selbstverständlich bieten wir Ihnen Leistungen im Bereich Finanzen, wie etwa den Aufbau eines Controlling-Systems oder Transaktionsberatungen auch unabhängig von Leistungen im Gebiet IT an. Im Bereich Personal stehen wir Ihnen bei der Personalsuche oder auch bei Interimslösungen zur Seite, um einen reibungslosen Ablauf des operativen Tagesgeschäfts zu gewährleisten. Durch unsere Erfahrung im Bereich Einkauf optimieren wir gezielt Ihre Wertschöpfungskette und erkennen Einsparpotenziale, wodurch Sie sich auf Ihre Kernprozesse konzentrieren können.",
        "parrafo3ContenidoNosotros": "Wir sehen den Knowhow-Transfer in Ihr Unternehmen als eine unserer Hauptaufgaben an und stehen Ihnen als kompetenter Ansprechpartner bei sämtlichen Fragen zur Verfügung. Die Besonderheiten der Kombination deutscher und türkischer Märkte, vor allem in Bezug auf Investitionsentscheidungen und deren erfolgreiche Umsetzung stellen dabei eines unserer Spezialgebiete dar.",
        "tituloContenidoContacto": "Kontaktieren Sie mit Uns",
        "parrafo1ContenidoContacto": "wenn Sie mehr Informationen über unser Unternehmen oder unsere Diesntleistungen, Senden Sie uns Bitte Ihre Kontaktdaten oder rufen Sie uns an. Wir werden Ihnen gerne behilftlich sein.",
        "tituloFormularioContacto": "Kontakt",
        "tituloIframeMapaContacto": "Location",
        "tituloContenidoServicios": "Unsere Dienstleistungen",
        "parrafo1ContenidoServicios": "Unser Unternehmen verfügt über umfassende Erfahrung in der Bereitstellung einer breiten Palette von Dienstleistungen und innovativen Lösungen für Kunden aus allen Branchen und mit unterschiedlichen Budgets.",
        "parrafo2ContenidoServicios": "Wenn Sie möchten, dass einer unserer Werbespots Sie besucht oder Sie uns in unseren Büros besuchen möchten, freut sich unser Team, Sie begrüßen zu dürfen.",
        "parrafo3ContenidoServicios": "Wir stellen Ihnen ein komplettes Produkt- und Lösungsportfolio zur Verfügung, das alle Ihre Bedürfnisse abdeckt.",
        "parrafo4ContenidoServicios": "Wir bieten eine breite Palette von Dienstleistungen an, die Ihrem Budget und den Prioritäten Ihres Unternehmens entsprechen.",
        "tituloContenidoProcesos": "PROZESMANAGEMENT",
        "parrafo1ContenidoProcesos": "Eine Organisation die sich einem ganzheitlichen Prozessdenken widmet und sich über die Wichtigkeit eines solchen Denkens bewusst ist, hat oftmals einen klaren Vorteil gegenüber der Konkurrenz. Häufig werden Probleme erst erkannt, wenn diese abteilungsübergreifend betrachtet werden. Eine solche Betrachtungsweise in Ihrem Unternehmen entstehen zu lassen, nehmen wir uns gerne als Ziel vor. Von der umfassenden Analyse Ihrer bestehenden Prozesse bis hin zur Schulung der optimierten Abläufe am Arbeitsplatz, decken wir mit unserem ganzheitlichen Ansatz alle Bereiche des Prozessmanagements ab. Neben dem Aufbau aussagekräftiger Informationssysteme für das Management, koordinieren, planen und optimieren wir Ihre Prozesse bis zur vollständigen Umsetzung im Unternehmen. Das dies bei laufendem Geschäftsbetrieb geschieht, ist für uns selbstverständlich. Sollten externe Zertifizierungen wie beispielsweise ISO 9001 geplant sein, helfen wir Ihnen auch hierzu, Ihre Abläufe so auszurichten, dass diese den vorgegebenen Standards entsprechen.",
        "tituloContenidoProyectos": "PROJEKTMANAGEMENT",
        "parrafo1ContenidoProyectos": "Im Projektmanagement bestehen heutzutage strikte Vorgaben, was zeitliche, finanzielle und personelle Ressourcen und deren effektiven und effizienten Einsatz in Projekten angeht. Viele Unternehmen haben gerade beim komplexen Zusammenspiel Probleme damit, diese Vorgaben einzuhalten und somit den Projekterfolg zu sichern und zu evaluieren – hier können wir Sie in allen Projektphasen unterstützend beraten, um solchen Eventualitäten zuvorzukommen. In zahlreichen Projekten haben wir durch strategisches und operatives Projektmanagement eine Vielzahl von Problemen in Unternehmen verschiedener Branchen gelöst. Bei dieser Tätigkeit zeichnen wir uns vor allem durch eine Projektübernahme bei voller Kostenverantwortung und Begleitung von der Konzeption bis hin zur erfolgreichen Umsetzung der Projekte aus. Ihre Zielvorgaben hinsichtlich zeitlichem und finanziellen Umfang berücksichtigen wir bereits strikt in der Planungsphase, sodass am Ende keine unerwarteten Kostenüberschreitungen entstehen.",
        "tituloContenidoFinanciero": "Finanzen",
        "enlace1Financiero:": "Finanzcontrolling",
        "enlace2Financiero": "Finanzbuchhaltung",
        "enlace3Financiero": "Transactionsberatung",
        "tituloContenidoControlFinanciero": "Finanzcontrolling",
        "parrafo1ContenidoControlFinanciero": "Gerade bei Expansionen ist es zum Teil schwer, die bestehenden Finanzstrukturen so im Blick zu behalten, dass an allen Unternehmensbereichen das Optimum hinsichtlich einer nachhaltigen und stabilen Finanzplanung gewährleistet ist. Zusätzlich steht oftmals das operative Tagesgeschäft im Finanzbereich im Vordergrund und die konsequente Orientierung an einem unternehmenssichernden Cash Management kommt zu kurz. Wir sichern Ihnen im Bereich Finanzcontrolling die dauerhafte Liquidität Ihres Unternehmens mit einer konsequenten Cash-Flow Planung durch Analyse und Optimierung der bestehenden Finanzstruktur sowie Berücksichtigung und Einhaltung der gesetzlichen Rahmenbedingungen. Auch bei der Suche nach Fremd- und Eigenkapital sind wir Ihnen gerne behilflich. Wir entwickeln und implementieren Management Informationssysteme, wie Compliance Strukturen, Risikomanagementsysteme oder IKS und unterstützen Sie im bei komplexen Themen wie Kostenrechnung und Controlling.",
        "tituloContenidoContabilidadFinanciera": "Finanzbuchhaltung",
        "parrafo1ContenidoContabilidadFinanciera": "Die Finanzbuchhaltung eines Unternehmens ist heute wichtiger denn je, um einen reibungslosen Ablauf im Unternehmen garantieren zu können. Dazu ist die Finanzbuchhaltung die Basis für ein erfolgreiches Controlling im Unternehmen. Im Bereich Finanzbuchhaltung bieten wir Ihnen ein individuell abgestimmtes Servicepaket an, welches üblicherweise die Analyse, Planung sowie Umsetzungsphase beinhaltet. Wir können die Leistung, der unter Einhaltung aller gesetzlichen Vorschriften sowie Berücksichtigung aktueller Änderungen, anbieten. Neben dem Aufbau von Finanzbuchhaltungssystemen und Unterstützung bei der Analyse, Konzeption und Auswahl von IT-Systemen, bereiten wir Sie optimal, unter Einhaltung aller rechtlichen Vorgaben, auf den Jahresabschluss vor. Außerdem beachten wir schon bei den Buchungsvorgängen, dass sämtliche für das Controlling notwendigen Informationen in den Buchungsvorgängen lückenlos erfasst werden.",
        "tituloContenidoAsesoramiento": "Transactionsberatung",
        "parrafo1ContenidoAsesoramiento": "Die Quote erfolgreicher Mergers and Acquisitions Transaktionen nimmt jährlich ab, was zum großen Teil auf mangelnde Kenntnis des kaufenden Unternehmens zurückzuführen ist. Dabei steht eine fundierte Due Diligence am Beginn einer erfolgreichen Transaktion. Im Bereich Transaktionsberatung übernehmen unsere Fachspezialisten die betriebswirtschaftliche, steuerliche und rechtliche Due Diligence. Hier führen wir Beratungen auf Käufer- sowie auf Verkäuferseite durch und erarbeiten gemeinsam mit Ihnen wirksame Integrations-Strategien getätigter Transaktionen. Unsere Beratung erstreckt sich hier von der Abspaltung von einzelnen Unternehmensteilen über Fusionen bis hin zu Übernahmen von Unternehmen aller Rechtsformen. Gerne unterstützen wir Sie auch bei herausfordernden Themen, wie die organisatorische Integration oder die Zusammenführung der IT-Landschaft, nach einer Übernahme oder Verschmelzung (Post-merger-integration).",
        "tituloContenidoControlPersonal": "PERSONAL",
        "enlace1Personal:": "Personalcontrolling",
        "enlace2Personal": "Interimsmanagement",
        "enlace3Personal": "Restrukturierung",
        "tituloContenidoPersonal": "Personalcontrolling",
        "parrafo1ContenidoPersonal": "Viele Unternehmen fragen sich, inwieweit eingesetztes Personal effektiv arbeitet und wie sich anhand von Kennzahlen messen lässt, ob zusätzliches Personal tatsächlich zu einem erhöhten Ergebnis des Unternehmens führt. Solche Erhebungen lassen sich einerseits aufgrund des Tagesgeschäfts nicht realisieren, andererseits spielen oftmals subjektive Faktoren eine Rolle. Wir bieten Ihnen die Entwicklung aussagefähiger Kennzahlensysteme an, anhand derer Sie sehen können, wie Sie im Vergleich zu der Branche abschneiden und bieten Ihnen weiterhin Maßnahmenpakete an, um dieses Arbeiten künftig effektiver gestalten zu können. Zusätzlich dazu bieten wir Ihnen auch die Bewertung Ihrer Mitarbeiter mithilfe einer Kompetenzmatrix sowie einer standardisierten Bewertung an. Auf diese Weise unterstützen wir Sie dabei, Ihre Mitarbeiter effektiv und sinnvoll einzusetzen. Auch bei der Kommunikation dieser Kennzahlen an das Management und die Mitarbeiter helfen wir Ihnen gerne.",
        "tituloContenidoGestionInterina": "Interimsmanagement",
        "parrafo1GestionInterina": "Stellenbesetzungen werden oftmals schwieriger, je höher die zu besetzende Stelle angesiedelt ist. Unternehmen lassen sich somit oft zu Besetzungen verleiten, die nicht ideal sind, um schnell eine Lösung parat zu haben und keine Lücke entstehen zu lassen. Diese Entscheidung kostet die jeweiligen Unternehmen jedoch oftmals viel Geld. Unsere Interimsmanager kümmern sich um die Besetzung von Positionen, die Sie kurzfristig aus vorhandenen Ressourcen nicht besetzen können oder für die Sie Expertise aus anderen Branchen oder Bereichen benötigen. Ob auf operativer Basis als CFO oder als Vorstand/GF – wir bieten Ihnen einen passenden Kandidaten. Soll die Stelle dann wieder von einem Mitarbeiter des Unternehmens übernommen werden, wird der neue Stelleninhaber von uns eingearbeitet. Ein Knowhow-Transfer mit entsprechender Prozess- und Tätigkeitsdokumentation ist dabei selbstverständlich für uns.",
        "tituloContenidoReestructuracion": "Restrukturierung",
        "parrafo1ContenidoReestructuracion": "Restrukturierungsmaßnahmen zielen darauf ab, Schwächen in der strategischen Ausrichtung zu beheben und auch damit verbundene nachteilige Kostenentwicklungen umzukehren. In der Praxis werden Maßnahmen meist erst dann eingeleitet, wenn der Ernstfall bereits eingetreten ist. Unsere erfahrenen Fachspezialisten wirken dem entgegen und begleiten Sie auf strategischer und operativer Ebene - im Idealfall - bevor die Notwendigkeit eintritt. Dabei liegt unser Fokus auf der Identifikation von Erfolgsfaktoren und der Umsetzung eines nachhaltigen und ökonomischen Denkens in Ihrem Unternehmen - auch im Rahmen von MBI, MBO oder Gesellschafterwechseln. Wir unterstützen Sie sowohl operativ als auch strategisch nach unserer bereits mehrfach erfolgreich angewandten Vorgehensweise. Dabei führen wir einen initialen Performance-Check durch, um die Ausgangssituation Ihres Unternehmens zu analysieren und Schwachstellen zu identifizieren. Basierend darauf bestimmen wir mit Ihnen den SOLL-Zustand und erarbeiten ein vollständiges Restrukturierungskonzept. Im nächsten Schritt werden die Maßnahmen abgeleitet und die Umsetzung gestartet und kontrolliert. Dabei übernehmen wir neben dem Projektmanagement auch das Monitoring, die Dokumentation und auch operative Aufgaben, z.B. im Controlling, auf Interimsbasis.",
        "tituloContenidoControlInformatica": "I.T",
        "enlace1Informatica:": "Informaticacontrolling",
        "enlace2Informatica": "Interimsmanagement",
        "enlace3Informatica": "Restrukturierung",
        "tituloContenidoIntroSistemas": "Systemeinführung",
        "parrafo1ContenidoIntroSistemas": "Konzepte und Beratungen zu Systemeinführungen gibt es viele. Allerdings hören die meisten Beratungen genau da auf, wo die Probleme beginnen – bei der Umsetzung. Mit einer Vielzahl von Unternehmen haben wir bereits ERP-Systeme neu eingeführt. Bei der Einführung stehen wir unseren Kunden nicht nur in der konzeptionellen Phase (also bei der Aufnahme der abzubildenden Prozesse, der Suche nach dem passenden ERP-System und der Auswahl des Systemhauses sowie der Finanzierung des Systems) zur Seite, sondern begleiten nach dieser Phase noch den Prozess der Einführung inkl. des Go-Lives und der Unterstützung nach der Implementierung des neuen Systems. Nur durch eine vollständige und strukturierte Konzeption kann ein neues System langfristig erfolgreich in Unternehmen implementiert werden. Dadurch können zusätzliche Folgekosten vermieden werden. Wir helfen Ihnen dabei diese Risiken zu vermeiden.",
        "tituloContenidoOptiSistemas": "Systemoptimierung",
        "parrafo1ContenidoOptiSistemas": "Durch die Einführung eines neuen ERP-Systems versprechen sich viele Unternehmen die Lösung sämtlicher Probleme und einen Neuanfang für das Unternehmen. Leider wird sehr häufig während der Auswahl eines neuen Systems kaum Wert auf die Aufnahme der abzubildenden Prozesse und unternehmensspezifischen Voraussetzungen, die das System leisten muss, gelegt. Dementsprechend bleibt der angestrebte Erfolg nach der Einführung des neuen Systems aus. In diesen Situationen bieten wir Unterstützung bei der Optimierung des bestehenden Systems und die damit einhergehende Optimierung der Unternehmensprozesse, inklusive einer nachvollziehbaren Dokumentation an. Auch in diesem Fall stehen wir unseren Kunden sowohl bei der konzeptionellen Phase, als auch bei der operativen Durchführung der Änderungen zur Seite.",
        "tituloContenidoMigraSistemas": "Systemmigration",
        "parrafo1ContenidoMigraSistemas": "Die Umstellung eines ERP-Systems ist nicht nur für kleine Unternehmen eine große Herausforderung. Dabei ist nicht zu vergessen, dass es sich bei der Migration eines ERP-Systems praktisch um den Austausch des zentralen Nervensystems, also einer Operation am offenen Herzen des Unternehmens handelt. Die tatsächliche Herausforderung dabei ist es, diesen Austausch so durchzuführen, dass das Tagesgeschäft nicht unter der Migration leidet, denn das Unternehmen muss zu dieser Zeit nicht nur mit Änderungen an Kernpunkten des Systems, sondern auch mit stark erhöhter Ressourcenauslastung zurechtkommen. Außerdem ist die Schnittstellenthematik bei dem Vorgang der Migration eines der wichtigsten Themen mit dem sich ein Unternehmen auseinandersetzen muss. Da wir bei diesen Prozessen bereits große Erfahrung gesammelt und mit vielen Unternehmen kooperiert haben, bieten wir ebenso eine Begleitung bei der Migration von ERP-Systemen – sowohl durch fachliche Unterstützung, als auch durch die Überlassung von Ressourcen, also Mitarbeitern – an.",
        "tituloContenidoCostes": "EINKAUF",
        "enlace1Costes:": "Supply Chain Management",
        "enlace2Costes": "Einkaufskostenoptimierung",
        "enlace3Costes": "Logistikoptimierung",
        "tituloContenidoGestionSumi": "Supply Chain Management",
        "parrafo1ContenidoGestionSumi": "Etliche Einkaufsabteilungen haben heute feste Lieferanten und Kooperationspartner, die frei nach dem Motto „ändere kein funktionierendes System“ beibehalten werden, während sich der Konkurrenzdruck aufbaut und sich Mitbewerber mit Optimierungsmöglichkeiten beschäftigen. Dass ein solches Verhalten nicht zukunftsträchtig ist, hat sich in der Praxis mehrfach bewiesen. Im Bereich Einkaufskostenoptimierung identifizieren wir Einsparpotenziale sowie Synergieeffekte und initiieren Projekte zur gezielten Reduktion Ihrer Einkaufskosten. Wir übernehmen das komplette Projektmanagement und unterstützen Sie bei Verhandlungen mit Lieferanten und weiteren Parteien, die für Ihren Einkaufsprozess relevant sein können. Während der Bearbeitung des Projekts, in Abstimmung mit Ihren Fachbereichen, entwickeln wir geeignete Reportings, welche den Projekterfolg zielgerichtet messen.",
        "tituloContenidoOptiCoste": "Einkaufskostenoptimierung",
        "parrafo1ContenidoOptiCoste": "Etliche Einkaufsabteilungen haben heute feste Lieferanten und Kooperationspartner, die frei nach dem Motto „ändere kein funktionierendes System“ beibehalten werden, während sich der Konkurrenzdruck aufbaut und sich Mitbewerber mit Optimierungsmöglichkeiten beschäftigen. Dass ein solches Verhalten nicht zukunftsträchtig ist, hat sich in der Praxis mehrfach bewiesen. Im Bereich Einkaufskostenoptimierung identifizieren wir Einsparpotenziale sowie Synergieeffekte und initiieren Projekte zur gezielten Reduktion Ihrer Einkaufskosten. Wir übernehmen das komplette Projektmanagement und unterstützen Sie bei Verhandlungen mit Lieferanten und weiteren Parteien, die für Ihren Einkaufsprozess relevant sein können. Während der Bearbeitung des Projekts, in Abstimmung mit Ihren Fachbereichen, entwickeln wir geeignete Reportings, welche den Projekterfolg zielgerichtet messen.",
        "tituloContenidoOptiLog": "Logistikoptimierung",
        "parrafo1ContenidoOptiLog": "Der Fachbereich Logistik ist vielschichtig und komplex, vor allem mit den Möglichkeiten, die sich heutzutage hinsichtlich des Optimierungspotenzials bieten. Im Bereich Logistikoptimierung bieten wir Ihnen die betriebswirtschaftliche Analyse und Optimierung des Bereichs Logistik an. Dabei verfolgen wir den Ansatz, dass wir geeignete Lösungen für Sie entwickeln, um Ihre Anforderungen im Bereich Logistik unter einem betriebswirtschaftlichen Gesichtspunkt zu erfüllen. Unsere Fachspezialisten verknüpfen hierzu das Knowhow aus den Bereichen Finanzen und IT. Unserem Beratungsansatz folgend entwickeln wir in Abstimmung mit Ihnen ein Konzept und stellen den Erfolg durch die Begleitung der Umsetzung sicher. In zahlreichen Projekten haben wir analoge Herausforderungen gelöst und unsere Fähigkeit, Lösungen gemäß des out-of-the-box Prinzips zu entwickeln, gezeigt.",
        "labelEmailForm": "Deine E-Mail: ",
        "labelMensajeForm": "Botschaft",
        "botonEnviarForm": "Senden",
        "botonContacto": "Kontaktiere uns",
        "botonLlamame" : "Ruf mich an",
        "tituloSMS": "Geben Sie Ihr Telefon ein und wir rufen Sie an",
        "telefonoSMS": "Telefon:",
        "mensajeSMS": "Nachricht hinzufügen (optional):",
        "cerrarSMS": "Schließen",
        "enviarSMS": "Senden",
        "enviadoSMS": "SMS erfolgreich versendet",
        "tituloFooterInformacionContacto": "KONTAKTINFORMATION",
        "tituloFooterRedesSociales": "SOZIALE NETZWERKE",
        "enlaceFooterAvisoYPoliticas": "Rechtliche Hinweise und Datenschutzbestimmungen Rechte © 2020 Reserviert",
        "novedades": "Neuheiten Agencia Tributaria",
        "tituloEnlacesImportantes": "Wichtige Links",
        "enlaceFooterAvisoYPoliticas": "Legal Notice and Privacy Policy | Rights © 2020 Reserved",
        "novedades": "News Agencia Tributaria",
        "tituloEnlacesImportantes": "Important links",
        "tituloLegal": "",
        "contenidoLegalCabecera": "",
        "localizacionInformacion": "",
        "nombreCompania": "",
        "direccionCompania": "",
        "emailCompania": "",
        "cifCompania": "",
        "registroCompania": "",
        "tituloPoliticaUso": "",
        "objetoTitulo": "",
        "primerParrafoPoliticaUso": "",
        "segundoParrafoPoliticaUso": "",
        "tercerParrafoPoliticaUso": "",
        "piePoliticaUso": "",
        "tituloUsoPaginaWeb": "",
        "primerParrafoUsoPaginaWeb": "",
        "segundoParrafoUsoPaginaWeb": "",
        "tercerParrafoUsoPaginaWeb": "",
        "cuartoParrafoUsoPaginaWeb": "",
        "tituloContenido": "",
        "primeroParrafoContenido": "",
        "segundoParrafoContenido": "",
        "tercerParrafoContenido": "",
        "tituloDerechosIntelectuales": "",
        "contenidoCabeceraDerechosIntelectuales": "",
        "tituloHypervinculosYSitios": "",
        "tituloHypervinculo": "",
        "primerParrafoHypervinculo": "",
        "segundoParrafoHypervinculo": "",
        "tercerParrafoHypervinculo": "",
        "tituloSitiosVinculados": "",
        "primerParrafoSitiosVinculados": "",
        "segundoParrafoSitiosVinculados": "",
        "tituloJurisdiccion": "",
        "contentidoCabeceraJurisdiccion": "",
        "tituloDatosPersonales": "",
        "tituloSegundoDatosPersonales": "",
        "contenidoDatosPersonales": "",
        "tituloTerceroDatosPersonales": "",
        "primerParrafoTerceroDatosPersonales": "",
        "emailYNombreTerceroDatosPersonales": "",
        "segundoParrafoTerceroDatosPersonales": "",
        "terceroParrafoTerceroDatosPersonales": "",
        "cuartoParrafoTerceroDatosPersonales": "",
        "quintoParrafoTerceroDatosPersonales": "",
        "sextoParrafoTerceroDatosPersonales": "",
        "porEmailDatosPersonales": "",
        "porEscritoDatosPersonales": ""
    },
    "es": {
        "menuInicio": "Inicio",
        "menuNosotros": "Sobre Nosotros",
        "menuContacto": "Contacto",
        "menuServicios": "Servicios",
        "menuGestionProcesos": "Gestión de procesos",
        "menuGestionProyectos": "Gestión de proyectos",
        "menuFinanciero": "Financiero",
        "menuPersonal": "Personal",
        "menuInformatica": "S.Informaticos I.T",
        "menuCostes": "Costes",
        "linea1EncabezadoPrincipal": "Asesoramiento Fiscal, Laboral y Contable",
        "linea2EncabezadoPrincipal": "Gestión de Empresas",
        "tituloContenidoPrincipal": "Inicio",
        "parrafo1ContenidoPrincipal": "Gracias por visitar nuestra nueva página web. Aquí podrás encontrar toda la información que necesites sobre nuestra empresa y sobre los servicios que ofrecemos.",
        "parrafo2ContenidoPrincipal": "Además, nuestro equipo humano está a tu disposición para asesorarte en todo momento sobre cualquier aspecto relativo a nuestro negocio.",
        "parrafo3ContenidoPrincipal": "Ponte en contacto con nosotros, será un placer ayudarte.",
        "tituloContenidoNosotros": "Nosotros",
        "parrafo1ContenidoNosotros": "Como Socio eficaz para el CFO…",
        "parrafo2ContenidoNosotros": "…Villa & Seco Asesores ofrece servicios integrales de Consultoría en las áreas de Finanzas, Informática, Recursos Humanos y Compras de una manera exhaustiva. En Villa & Seco Asesores seguimos un enfoque de Consultoría Integral, cuyo objetivo es acompañar de forma conjunta a nuestros Clientes, desarrollando y aplicando soluciones activamente. Ello nos distingue por el hecho de que no sólo desarrollamos recomendaciones para nuestros clientes, sino también por ayudar a su aplicación. Con este fin nuestros Consultores están disponibles para ayudar durante todas las etapas del Proyecto. Nuestro trabajo nos hace destacar, en particular, mediante la vinculación de nuestros conocimientos en varias disciplinas. Por ello podemos ofrecer nuestra experiencia en Finanzas e Informática reunida en numerosos proyectos. Además de la introducción y la optimización de las herramientas informáticas Primarias y secundarias, ofrecemos el desarrollo y la reorientación de los distintos departamentos usando un soporte de Técnicas Informáticas. Por supuesto, ofrecemos servicios en el Área Finanzas, tales como la creación e implantación de un Sistema de Controlling, así como transacciones con total independencia de las Técnicas Informáticas.",
        "parrafo3ContenidoNosotros": "En el área de personal estamos a su lado en la búsqueda de personal cualificado o incluso con soluciones Interinas, para así garantizar el buen desarrollo operativo de la actividad diaria. A través de nuestra experiencia en el Sector Compras, optimizamos selectivamente la cadena de Compras identificando su potencial de ahorro, lo que le permite centrarse en sus procesos centrales. Vemos la transferencia de Know-how en su Empresa como una de nuestras principales tareas, estando disponibles como un Socio competente para todas sus preguntas. La combinación especial de los mercados europeos, sobre todo en cuanto a las decisiones de Inversión y su aplicación exitosa, representan una de nuestras especialidades.",
        "tituloContenidoContacto": "Contacta con nosotros",
        "parrafo1ContenidoContacto": "Si necesitas más información sobre nuestra empresa o sobre nuestro servicios, ponte en contacto con nosotros hoy mismo a través del siguiente formulario, llámanos o ven a vernos a nuestras oficinas. Estaremos encantados de atenderte.",
        "tituloFormularioContacto": "Formulario de contacto",
        "tituloIframeMapaContacto": "Nuestra ubicación",
        "tituloContenidoServicios": "Nuestros servicios",
        "parrafo1ContenidoServicios": "Nuestra empresa tiene una dilatada experiencia en proporcionar una amplia gama de servicios y soluciones innovadoras a clientes de todos los sectores y con diferentes presupuestos.",
        "parrafo2ContenidoServicios": "Tanto si quieres que uno de nuestros comerciales pase a visitarte como si prefieres venir a vernos a nuestras oficinas, nuestro equipo estará encantado de recibirte.",
        "parrafo3ContenidoServicios": "Ponemos a tu disposición un completo portfolio de productos y soluciones para cubrir todas las necesidades.",
        "parrafo4ContenidoServicios": "Ofrecemos una amplia gama de servicios acordes a tu presupuesto y a las prioridades de tu negocio.",
        "tituloContenidoProcesos": "GESTIÓN DE PROCESOS",
        "parrafo1ContenidoProcesos": "Una organización dedicada a un proceso holístico y que es consciente de la importancia de este tipo de pensamiento, a menudo tiene una clara ventaja sobre la competencia. Los problemas más comunes únicamente se detectan cuando se mira en todos los Departamentos. Tomamos como objetivo ver su empresa como tal. A partir del análisis integral de los procesos existentes en el lugar de trabajo para la formación de procesos optimizados, cubrimos todas las áreas de gestión de procesos. Además de la construcción de sistemas de información significativa para la gestión, coordinación, planificación y optimización de sus procesos para su implementación en la empresa. Que esto suceda con los negocios en curso, es para nosotros una cuestión de rutina. En caso de que deba preverse certificaciones externas tales como ISO 9001, le ayudamos también en esto, alinear sus procesos para que cumplan con la normativa indicada.",
        "tituloContenidoProyectos": "GESTIÓN DE PROYECTOS",
        "parrafo1ContenidoProyectos": "En la Gestión de Proyectos en la actualidad existen reglas estrictas, financieras y de recursos humanos, que ponen en cuestión su uso eficaz y eficiente. Muchas empresas tienen problemas complejos de interacción para cumplir con estos requisitos y por lo tanto asegurar el éxito del proyecto- en este sentido le apoyamos en todas las fases del proyecto con el fin de evitar tal eventualidad. En numerosos proyectos hemos logrado por medio de una la gestión estratégica y operativa del Proyecto dar solución a una gran variedad de problemas en Empresas de diversos sectores. Esta actividad se caracteriza principalmente por la adquisición responsable y el apoyo desde la concepción hasta la implementación exitosa de los proyectos. Los objetivos relativos de ámbito temporal y financiero se consideran estrictamente ya en la etapa de planificación, por lo que al final no se producen excesos inesperados en los Costes.",
        "tituloContenidoFinanciero": "financiero",
        "enlace1Financiero:": "Control financiero",
        "enlace2Financiero": "Contabilidad financiera",
        "enlace3Financiero": "Accesoramiento en transacciones",
        "tituloContenidoControlFinanciero": "Control Financiero",
        "parrafo1ContenidoControlFinanciero": "A veces y especialmente tras una expansión, es difícil mantener las estructuras financieras existentes. Esto se garantiza por medio de una planificación financiera sostenible y estable. Además, a menudo se toma en un primer plano, nota de las operaciones diarias en el sector operativo y se descuida la orientación constante de la gestión del efectivo respaldado por la Empresa. Le aseguramos el Control Financiero permanente de la Liquidez de su empresa con una planificación del flujo de efectivo constante mediante el Análisis y la optimización de la estructura financiera existente, observando y cumpliendo los requisitos legales. Incluso en la búsqueda de Capital, estaremos encantados de ayudarle. Desarrollamos e implementamos sistemas de información, tales como estructuras de cumplimiento, sistemas de gestión de riesgos o ICS y le apoyamos en el caso de cuestiones complejas tales como la Contabilidad de Costes y Controlling.",
        "tituloContenidoContabilidadFinanciera": "Contabilidad Financiera",
        "parrafo1ContenidoContabilidadFinanciera": "La Contabilidad Financiera de una Empresa hoy en día es más importante que nunca a fin de garantizar el buen funcionamiento de la misma. Hoy en día el Control de la Contabilidad Financiera es la base del éxito en el control de la Empresa. En el ámbito de la Contabilidad Financiera, ofrecemos un paquete de servicios adaptados individualmente, que por lo general implica el análisis, la planificación y la fase de implementación. Teniendo en cuenta los cambios actuales y los requisitos legales ofrecemos mejorar el rendimiento financiero de la Empresa. Además del establecimiento de sistemas de Contabilidad Financiera, le asistimos en el análisis, diseño y selección de nuevos Sistemas Informáticos, informándole de manera óptima, cumpliendo con todos los requisitos legales.",
        "tituloContenidoAsesoramiento": "Asesoramiento en Transacciones",
        "parrafo1ContenidoAsesoramiento": "La tasa de éxito fusiones y adquisiciones disminuye cada año, debido en gran medida a la falta de conocimiento de la empresa compradora. Una debida Due Diligence es de gran importancia como principio para una transacción exitosa. En este ámbito nuestros especialistas se hacen cargo del estudio de la Negociación, asesorándole en los Asuntos Fiscales y realizando la Auditoría legal. Realizamos consultas sobre el comprador y / o el vendedor y trabajamos con usted las estrategias de integración más eficaces. Nuestro asesoramiento se extiende desde la eliminación de las unidades de negocio de las fusiones hasta las adquisiciones de empresas de todos los tamaños. Estamos encantados de ayudarle en temas difíciles, como la organización integral o la fusión de infraestructuras de Técnicas Informáticas, después de una adquisición o fusión (integración posterior a la fusión).",
        "tituloContenidoControlPersonal": "Personal",
        "enlace1Personal:": "Control de personal",
        "enlace2Personal": "Gestión de administración interina",
        "enlace3Personal": "Reestructuración",
        "tituloContenidoPersonal": "Control de Personal",
        "parrafo1ContenidoPersonal": "Muchas empresas se preguntan cómo hacer que la mano de obra (Empleados) funcione eficazmente y, como se puede medir, a través de indicadores, que personal adicional en realidad conduce a un mayor rendimiento de la Empresa. Estos indicadores pueden ser, por un lado, la actividad diaria, por otra parte, a menudo se encuentran implicados otros factores subjetivos. Nosotros ofrecemos el desarrollo de sistemas de medición de rendimiento significativo sobre la base de los cuales se puede ver cómo se comparan con el resto de la industria, proporcionándole paquetes de medidas adicionales para hacer este trabajo en el futuro de una manera más eficaz. Además, también ofrecemos la evaluación de sus empleados utilizando una matriz de competencias y una evaluación estandarizada. De esta manera, le apoyamos, de manera eficaz y práctica como organizar a sus empleados. También le ofrecemos ayuda en la comunicación y la gestión del personal.",
        "tituloContenidoGestionInterina": "Gestión de administración interina",
        "parrafo1GestionInterina": "Scen nicht Cuanto más alto sea el puesto a cubrir más difícil resulta a la Empresa encontrar personal cualificado. Las empresas, por tanto, a menudo buscan cubrir estos puestos con soluciones rápidas que no son ideales. Estas decisiones, sin embargo, a menudo con llevan un aumento de los Costes. Nuestros Administradores Interinos se encargan de la ocupación de esas posiciones que con los recursos existentes no se pueden ocupar el corto plazo o para los que se requieren conocimientos de otras industrias o regiones. De una manera operativa como CFO o Ejecutiva / Manager - ofrecemos un candidato adecuado. Cuando el puesto debe ser ocupado por un empleado de la Empresa, el titular del puesto será adiestrado de manera adecuada por nosotros. La transferencia de conocimientos en los procesos apropiados y la documentación de la actividad es una cuestión de rutina para nosotros.",
        "tituloContenidoReestructuracion": "Reestructuración",
        "parrafo1ContenidoReestructuracion": "Medidas de reestructuración pretenden remediar las deficiencias en la dirección estratégica y revertir las tendencias de costos adversos. En la práctica, estas medidas en general sólo se inician cuando ya se ha producido la emergencia. Nuestros especialistas experimentados ayudan a contrarrestar y le acompañan a nivel estratégico y operativo - idealmente - antes de que ocurra la necesidad. Nuestra atención se centra en la identificación de los factores de éxito y la implementación de un plan económico sostenible para su empresa - también bajo MBI, MBO o cambios de socios.   Apoyamos tanto operativa, como estratégicamente en consonancia con nuestro enfoque, ya aplicado con existo en distintas ocasiones. Llevamos a cabo una comprobación del rendimiento inicial para analizar la situación actual de su empresa y para identificar las debilidades. En función de ello, con usted, se determinan Metas elaborando un plan de reestructuración completa. Nos hacemos cargo además de la Gestión de Proyectos, así como de su supervisión, documentación y tareas operativas, tales como por ejemplo en el control de Costos (Controlling), en calidad de Manager Interino.",
        "tituloContenidoControlInformatica": "S. Informaticos IT.",
        "enlace1Informatica:": "Introducción de sistemas",
        "enlace2Informatica": "Optimización de sistemas",
        "enlace3Informatica": "Migración de sistemas",
        "tituloContenidoIntroSistemas": "Introducción de sistemas",
        "parrafo1ContenidoIntroSistemas": "Conceptos y consultas sobre implementación de nuevos Sistemas ERP encontramos muchos. Sin embargo, en la mayoría de las discusiones oímos exactamente donde comienzan los problemas - en su aplicación. En una gran variedad de empresas, ya hemos introducido Sistemas ERP. Durante la introducción, nosotros (es decir, durante el estudio, la búsqueda, la financiación y la implantación del sistema ERP) estamos al lado de nuestros clientes. Dándole apoyo en la implementación del nuevo sistema. Sólo a través de un diseño completo y estructurado, un nuevo sistema puede ser implementado con éxito en las empresas a largo plazo. Dando como resultado, costes adicionales derivados de la misma. Nosotros ayudamos a evitar estos riesgos.",
        "tituloContenidoOptiSistemas": "Optimización de sistema",
        "parrafo1ContenidoOptiSistemas": "Con la introducción de un nuevo sistema ERP, muchas empresas prometen la solución a todos los problemas, y un nuevo comienzo para la Empresa. Desafortunadamente a menudo no se tienen en cuenta los requisitos específicos de cada empresa, a la hora de seleccionar un nuevo sistema. En consecuencia, el efecto deseado después de la introducción del nuevo sistema no se nota. En estas situaciones, ofrecemos apoyo en la optimización del sistema existente y la consiguiente optimización de los procesos de negocio, incluyendo una documentación comprensible. También en este caso, apoyamos a nuestros clientes tanto en la fase conceptual, así como en la aplicación operativa de los cambios.",
        "tituloContenidoMigraSistemas": "Migración de sistemas",
        "parrafo1ContenidoMigraSistemas": "La conversión de un sistema ERP es un reto importante no sólo para las pequeñas empresas. No hay que olvidar que prácticamente es la sustitución del sistema nervioso central, es decir, una operación a corazón abierto de la empresa. El desafío real es, realizar estos intercambios de forma que el trabajo diario no sufra de la migración, ya que la empresa tiene que hacer frente en este momento no sólo a cambios en los elementos clave del sistema, sino también en gran medida al aumento de la utilización de recursos. Además, los problemas de interfaz en el proceso de la migración es una de las cuestiones más importantes que una empresa tiene que afrontar. La experiencia adquirida una experiencia en estos procesos nos permite también ofrecer soporte en la migración de los sistemas ERP - tanto a través de asistencia técnica, así como a través de la provisión de recursos, es decir, los empleados.",
        "tituloContenidoCostes": "Costes",
        "enlace1Costes:": "Gestión de la cadena de suministros",
        "enlace2Costes": "Optimización de costes",
        "enlace3Costes": "Optimización logística",
        "tituloContenidoGestionSumi": "Gestión de la cadena de suministros",
        "parrafo1ContenidoGestionSumi": "Cargas excesivas se encuentran a menudo donde menos se espera y el ahorro se hace en los lugares equivocados. Esta percepción puede hoy en día ahorrar mucho dinero - y por lo tanto mantener los Costes por decisiones equivocadas, dentro de un margen. En el área de gestión de la cadena de suministro, nos esforzamos en realizar un proceso de optimización de los recursos de todas sus áreas, desde proveedores de materias primas hasta el Cliente final. Identificamos factores de coste y los cuellos de botella, desarrollando en la misma empresa, procesos de adquisición estandarizados permitiéndole así centrarse en sus procesos principales, sin que se pierdan pensamientos en una mala asignación de recursos. Durante la realización del proyecto, mediante consulta con los distintos departamentos, desarrollamos informes adecuados, que miden el éxito de un proyecto bien orientado.",
        "tituloContenidoOptiCoste": "Optimización de Costes",
        "parrafo1ContenidoOptiCoste": "En muchos departamentos de compras han sido fijadas líneas de mantenimiento con el fin de que no existan cambios de funcionamiento en el Sistema Que tal comportamiento no es prometedor, ha sido demostrado en infinidad ocasiones en la práctica. Con la optimización de Costes identificamos posibles puntos de ahorro y sinergias e iniciamos proyectos para reducir estratégicamente sus Costos. En este sentido nos encargamos de la gestión completa del proyecto y le ayudamos en las negociaciones con los proveedores y otras partes que puedan ser relevantes para su proceso de compra. Durante la tramitación del proyecto, en consulta con los distintos departamentos, desarrollamos informes adecuados, que miden el éxito de un proyecto orientado.",
        "tituloContenidoOptiLog": "Optimización logística",
        "parrafo1ContenidoOptiLog": "El Departamento de Logística es complejo, sobre todo con las posibilidades que ofrece hoy en día en términos del potencial de optimización. En la optimización de la logística, ofrecemos un análisis de negocio y la optimización de la logística. Creemos en el análisis que desarrollamos, encontrando soluciones adecuadas para usted, para satisfacer sus necesidades en materia de logística bajo un punto de vista empresarial. Nuestros especialistas combinan estos conocimientos en los campos de las finanzas y de TI. Después de nuestra consultoría analítica, desarrollamos en colaboración con ustedes un concepto de optimización y un seguimiento de su implementación. En numerosos proyectos que hemos desarrollado hemos resuelto problemas similares lo que demuestra nuestra capacidad para encontrar y aplicar soluciones de acuerdo con el principio out-of- the-box.",
        "labelEmailForm": "Su email: ",
        "labelMensajeForm": "Mensaje: ",
        "botonEnviarForm": "Enviar",
        "botonContacto": "Contáctenos",
        "botonLlamame": "Llámame",
        "tituloSMS": "Introduzca su teléfono y le llamamos",
        "telefonoSMS": "Teléfono:",
        "mensajeSMS": "Agregar un mensaje (Opcional):",
        "cerrarSMS": "Cerrar",
        "enviarSMS": "Enviar",
        "enviadoSMS": "SMS enviado correctamente",
        "tituloFooterInformacionContacto": "INFORMACIÓN DE CONTACTO",
        "tituloFooterRedesSociales": "REDES SOCIALES",
        "enlaceFooterAvisoYPoliticas": "Aviso Legal y Política de Privacidad | Derechos Reservados © 2020",
        "novedades": "Novedades Agencia Tributaria",
        "tituloEnlacesImportantes": "Enlaces importantes",
        "enlaceFooterAvisoYPoliticas": "Legal Notice and Privacy Policy | Rights © 2020 Reserved",
        "novedades": "News Agencia Tributaria",
        "tituloEnlacesImportantes": "Important links",
        "tituloLegal": "",
        "contenidoLegalCabecera": "",
        "localizacionInformacion": "",
        "nombreCompania": "",
        "direccionCompania": "",
        "emailCompania": "",
        "cifCompania": "",
        "registroCompania": "",
        "tituloPoliticaUso": "",
        "objetoTitulo": "",
        "primerParrafoPoliticaUso": "",
        "segundoParrafoPoliticaUso": "",
        "tercerParrafoPoliticaUso": "",
        "piePoliticaUso": "",
        "tituloUsoPaginaWeb": "",
        "primerParrafoUsoPaginaWeb": "",
        "segundoParrafoUsoPaginaWeb": "",
        "tercerParrafoUsoPaginaWeb": "",
        "cuartoParrafoUsoPaginaWeb": "",
        "tituloContenido": "",
        "primeroParrafoContenido": "",
        "segundoParrafoContenido": "",
        "tercerParrafoContenido": "",
        "tituloDerechosIntelectuales": "",
        "contenidoCabeceraDerechosIntelectuales": "",
        "tituloHypervinculosYSitios": "",
        "tituloHypervinculo": "",
        "primerParrafoHypervinculo": "",
        "segundoParrafoHypervinculo": "",
        "tercerParrafoHypervinculo": "",
        "tituloSitiosVinculados": "",
        "primerParrafoSitiosVinculados": "",
        "segundoParrafoSitiosVinculados": "",
        "tituloJurisdiccion": "",
        "contentidoCabeceraJurisdiccion": "",
        "tituloDatosPersonales": "",
        "tituloSegundoDatosPersonales": "",
        "contenidoDatosPersonales": "",
        "tituloTerceroDatosPersonales": "",
        "primerParrafoTerceroDatosPersonales": "",
        "emailYNombreTerceroDatosPersonales": "",
        "segundoParrafoTerceroDatosPersonales": "",
        "terceroParrafoTerceroDatosPersonales": "",
        "cuartoParrafoTerceroDatosPersonales": "",
        "quintoParrafoTerceroDatosPersonales": "",
        "sextoParrafoTerceroDatosPersonales": "",
        "porEmailDatosPersonales": "",
        "porEscritoDatosPersonales": ""
    },
    "en": {
        "menuInicio": "Introduction",
        "menuNosotros": "About us",
        "menuContacto": "Contact",
        "menuServicios": "Services",
        "menuGestionProcesos": "Process management",
        "menuGestionProyectos": "Proyect management",
        "menuFinanciero": "Financial",
        "menuPersonal": "Personal",
        "menuInformatica": "I.T",
        "menuCostes": "Costs",
        "linea1EncabezadoPrincipal": "Tax, Labor and Accounting counseling",
        "linea2EncabezadoPrincipal": "Business management",
        "tituloContenidoPrincipal": "Introduction",
        "parrafo1ContenidoPrincipal": "Thanks for visiting us. In here you can find all the information you need about our company and the services we offer.",
        "parrafo2ContenidoPrincipal": "Aditionally, our team is at your full disposal to advise you at all times about any aspect regarding our business.",
        "parrafo3ContenidoPrincipal": "Get in touch with us, we will be happy to assist you.",
        "tituloContenidoNosotros": "Us",
        "parrafo1ContenidoNosotros": "As an effective partner of the CFO…",
        "parrafo2ContenidoNosotros": "…Villa & Seco Advisers offer comprehensive consulting services in Finance, IT, Human Resources and Purchasing, in an exhaustive way. At Villa & Seco Asesores we follow a Comprehensive Consulting approach, which objective is to accompany our clients in a combined way, developing and applying solutions actively. This distinguishes us cause of the fact that we not only develop recommendations for our clients, but also supporting your business. Because of this our Consultants are available to help during all stages of the Project. Our work makes us stand out, in particular, by linking our knowledge in various disciplines. Therefore we can offer our experience in Finance and IT gathered in numerous projects. In addition to the introduction and optimization of Primary and Secondary IT tools, we offer the development and reorientation of the different departments using IT Techniques support. Of course, we offer services in the Finance Area, such as the creation and implementation of a Controlling System, as well as transactions with total independence from Computer Techniques.",
        "parrafo3ContenidoNosotros": "We are at with you in the search for qualified staff or even with Interin solutions, so that way we can guarantee a good development of daily activity. Throughout our experience in the Cost Sector, we selectively optimize the Purchasing chain identifying its savings potential, allowing you to focus on your core processes. We see the transfer of Know-how in your Company as one of our main tasks, being available as a competent Partner for all your questions. The special combination of European markets, especially in terms of investment decisions and their successful application, represent one of our specialties.",
        "tituloContenidoContacto": "Contact us",
        "parrafo1ContenidoContacto": "If you need more information about our services or our company, get in touch with us right now through the next form, call us or visit our offices. We will be happy to heed you.",
        "tituloFormularioContacto": "Contact Form",
        "tituloIframeMapaContacto": "Our location",
        "tituloContenidoServicios": "Our services",
        "parrafo1ContenidoServicios": "Our company has extensive experience in providing a wide range of services and innovative solutions to clients in all sectors with different kind of budgets.",
        "parrafo2ContenidoServicios": "Whether you want one of our sales representatives to come visit you or if you prefer to come and see us at our offices, our team will be happy to receive you.",
        "parrafo3ContenidoServicios": "We put at your disposal a complete portfolio of products and solutions to cover all needs.",
        "parrafo4ContenidoServicios": "We offer a wide range of services according your budget and your businees priorities.",
        "tituloContenidoProcesos": "Process management",
        "parrafo1ContenidoProcesos": "An organization dedicated to a holistic process and is aware of the importance of this type of reflection, oftenly it has a clear advantage over the competence. Most common problems are detected uniquely when we have a look at all Departments. We aim to see your company as such. From the comprehensive analysis of the existing processes in the workplace to the formation of optimized processes, we cover all areas of process management. In addition to the construction of significant information systems for the management, coordination, planification and optimization of its processes for its implementation in the company. This happening is businesses running, it is for us a matter of routine. In the event that external certifications such as ISO 9001 must be provided, we also help you in this, aligning your processes so that they comply with the indicated regulations.",
        "tituloContenidoProyectos": "Proyect management",
        "parrafo1ContenidoProyectos": "In Project Management, there are currently strict financial and human resources rules that call into question their effective and efficient use. Many companies have complex interaction problems to meet these requirements and therefore ensure the success of the project - in this sense we support you in all phases of the project in order to avoid such an eventuality. In numerous projects we have managed, through a strategic and operational management of the Project, to solve a great variety of problems in companies from various sectors. This activity is mainly characterized by responsible procurement and support from conception to successful implementation of projects. The relative temporal and financial objectives are strictly considered already in the planning stage, so that in the end there are no unexpected excesses in Costs.",
        "tituloContenidoFinanciero": "Financial",
        "enlace1Financiero:": "Financial control",
        "enlace2Financiero": "Financial accounting",
        "enlace3Financiero": "Transaction advise",
        "tituloContenidoControlFinanciero": "Financial Control",
        "parrafo1ContenidoControlFinanciero": "Sometimes and especially after an expansion, it is difficult to maintain existing financial structures. This is ensured through stable and sustainable financial planning. In addition, the daily operations in the operating sector are often taken to the fore, and the constant guidance of the management of cash backed by the Company is neglected. We assure you the permanent Financial Control of the Liquidity of your company with a constant cash flow planning through the Analysis and optimization of the existing financial structure, observing and complying with the legal requirements. Even when looking for Capital, we will be happy to help you. We develop and implement information systems, such as compliance structures, risk management systems or ICS and we support you in the case of complex issues such as Cost Accounting and Controlling.",
        "tituloContenidoContabilidadFinanciera": "Financial Accounting",
        "parrafo1ContenidoContabilidadFinanciera": "The Financial Accounting of a Company today is more important than ever in order to guarantee its proper functioning. Nowadays the Control of Financial Accounting is the basis of success in controlling the Company. In the field of Financial Accounting, we offer an individually tailored service package, which generally involves analysis, planning and implementation phase. Taking into account current changes and legal requirements we offer to improve the financial performance of the Company. In addition to the establishment of Financial Accounting systems, we assist you in the analysis, design and selection of new Computer Systems, informing you in an optimal way, complying with all legal requirements.",
        "tituloContenidoAsesoramiento": "Transactions advise",
        "parrafo1ContenidoAsesoramiento": "The M&A success rate decreases each year, largely due to a lack of knowledge of the purchasing company. Due Due Diligence is of great importance as a principle for a successful transaction. In this area, our specialists take charge of the Negotiation study, advising you on Tax Matters and carrying out the legal Audit. We make inquiries about the buyer and / or the seller and work with you the most effective integration strategies. Our advice ranges from the elimination of business units from mergers to the acquisitions of companies of all sizes. We are happy to help you on difficult issues, such as the comprehensive organization or the merger of Técnicas Informáticas infrastructures, after an acquisition or merger (post-merger integration).",
        "tituloContenidoControlPersonal": "Personal",
        "enlace1Personal:": "Staff control",
        "enlace2Personal": "Interim administration management",
        "enlace3Personal": "Restructuring",
        "tituloContenidoPersonal": "Staff Control",
        "parrafo1ContenidoPersonal": "Many companies wonder how to make the workforce (Employees) work effectively and, as can be measured, through indicators, that additional personnel actually leads to a higher performance of the Company. These indicators can be, on the one hand, daily activity, on the other hand, other subjective factors are often involved. We offer the development of meaningful performance measurement systems on the basis of which you can see how they stack up against the rest of the industry, providing you with additional measurement packages to do this job more effectively in the future. In addition, we also offer evaluation of your employees using a competency matrix and a standardized assessment. In this way, we support you, in an effective and practical way as you organize your employees. We also offer help in communication and personnel management.",
        "tituloContenidoGestionInterina": "Interim administration management",
        "parrafo1GestionInterina": "The higher the position to be filled, the more difficult it is for the Company to find qualified personnel. Companies therefore often seek to fill these positions with less than ideal quick fixes. These decisions, however, often lead to increased Costs. Our Interim Administrators take care of those positions that with existing resources cannot be filled in the short term or for which knowledge of other industries or regions is required. In an operational way as CFO or Executive / Manager - we offer a suitable candidate. When the position must be filled by an employee of the Company, the holder of the position will be adequately trained by us. Knowledge transfer in the appropriate processes and documentation of the activity is a matter of course for us.",
        "tituloContenidoReestructuracion": "Restructuring",
        "parrafo1ContenidoReestructuracion": "Restructuring measures are intended to remedy deficiencies in strategic direction and reverse adverse cost trends. In practice, these measures are generally only initiated when the emergency has already occurred. Our experienced specialists help counter and accompany you on a strategic and operational level - ideally - before the need occurs. Our focus is on identifying the success factors and implementing a sustainable economic plan for your company - also under MBI, MBO or partner changes. We support both operationally and strategically in line with our approach, already applied with success on different occasions. We carry out an initial performance check to analyze the current situation of your business and to identify weaknesses. Based on this, with you, Goals are determined by drawing up a complete restructuring plan. We also take charge of Project Management, as well as its supervision, documentation and operational tasks, such as for example in Cost Control (Controlling), as Interim Manager.",
        "tituloContenidoControlInformatica": "I.T",
        "enlace1Informatica:": "Systems introduction",
        "enlace2Informatica": "Systems optimization",
        "enlace3Informatica": "Systems migration",
        "tituloContenidoIntroSistemas": "Systems Introduction",
        "parrafo1ContenidoIntroSistemas": "Concepts and queries about the implementation of new ERP systems we find many. However, in most discussions we hear exactly where the problems begin - in their application. In a wide variety of companies, we have already introduced ERP Systems. During the introduction, we (that is, during the study, the search, the financing and the implantation of the ERP system) we are next to our clients. Giving you support in the implementation of the new system. Only through a complete and structured design, a new system can be successfully implemented in companies in the long term. Resulting in additional costs derived from it. We help avoid these risks.",
        "tituloContenidoOptiSistemas": "Systems optimization",
        "parrafo1ContenidoOptiSistemas": "With the introduction of a new ERP system, many companies promise the solution to all problems, and a new beginning for the Company. Unfortunately, the specific requirements of each company are often not taken into account when selecting a new system. Consequently, the desired effect after the introduction of the new system is not noticeable. In these situations, we offer support in the optimization of the existing system and the consequent optimization of business processes, including an understandable documentation. Also in this case, we support our clients both in the conceptual phase, as well as in the operational application of the changes.",
        "tituloContenidoMigraSistemas": "Systems migration",
        "parrafo1ContenidoMigraSistemas": "Converting an ERP system is a major challenge not only for small businesses. It should not be forgotten that it is practically the replacement of the central nervous system, that is, an open heart operation of the company. The real challenge is to carry out these exchanges in such a way that the daily work does not suffer from migration, since the company has to face at this moment not only changes in the key elements of the system, but also to a large extent the increase in the use of resources. In addition, interface problems in the migration process is one of the most important issues that a company has to face. The experience acquired in these processes also allows us to offer support in the migration of ERP systems - both through technical assistance, as well as through the provision of resources, that is, employees.",
        "tituloContenidoCostes": "Costs",
        "enlace1Costes:": "Supply chain management",
        "enlace2Costes": "Cost optimization",
        "enlace3Costes": "Logistics optimization",
        "tituloContenidoGestionSumi": "Supply Chain Management",
        "parrafo1ContenidoGestionSumi": "Excessive charges are often found where least expected, and savings are made in the wrong places. This perception can save a lot of money today - and therefore keep costs for wrong decisions, within a margin. In the area of supply chain management, we strive to optimize the resources of all its areas, from raw material suppliers to the end customer. We identify cost factors and bottlenecks, developing in the same company, standardized procurement processes, thus allowing you to focus on your main processes, without losing thoughts in a misallocation of resources. During the project, through consultation with the different departments, we develop appropriate reports that measure the success of a well-targeted project.",
        "tituloContenidoOptiCoste": "Cost optimization",
        "parrafo1ContenidoOptiCoste": "In many purchasing departments, maintenance lines have been established so that there are no operational changes in the System. That such behavior is not promising, it has been demonstrated on countless occasions in practice. With the optimization of Costs, we identify possible points of savings and synergies and we initiate projects to strategically reduce their Costs. In this sense, we take care of the complete management of the project and help you in negotiations with suppliers and other parties that may be relevant to your purchasing process. During the processing of the project, in consultation with the different departments, we develop appropriate reports, which measure the success of a targeted project.",
        "tituloContenidoOptiLog": "Logistics optimization",
        "parrafo1ContenidoOptiLog": "The Logistic Department is complex, specially with the possibilities that offers nowadays in terms of optimization potential. In Logistic optimization, we offer a full analysis and logistic optimization. We believe the analysis we develop, we find solutions adequated to you, to satisfy your needs in logistics from a business point of view. Our specialists combine this knowledge in the fields of finance and IT. After our analytical consultancy, we develop in collaboration with you an optimization concept and a follow-up of its implementation. In numerous projects we have developed we have solved similar problems, which shows our ability to find and apply solutions according to the out-of-the-box principle",
        "botonContacto": "Contact us",
        "botonLlamame": "Call me",
        "tituloSMS": "Enter your phone and we'll call you",
        "telefonoSMS": "Phone:",
        "mensajeSMS": "Add a message (optional):",
        "cerrarSMS": "Close",
        "enviarSMS": "Send",
        "enviadoSMS": "SMS sent successfully",
        "tituloFooterInformacionContacto": "CONTACT INFORMATION",
        "tituloFooterRedesSociales": "SOCIAL NETWORKS",
        "enlaceFooterAvisoYPoliticas": "Legal Notice and Privacy Policy | Rights © 2020 Reserved",
        "novedades": "News Agencia Tributaria",
        "tituloEnlacesImportantes": "Important links",
        "tituloLegal": "LEGAL NOTICE AND PRIVACY POLICY",
        "contenidoLegalCabecera": "In compliance with article 10 of Law 34/2002, of July 11, on services of the information society and electronic commerce, you are informed that the domain www.vysasesores.com, now on 'web page' is registered in the name of Villa y Seco Asesores SL",
        "localizacionInformacion": "You can locate us through the following information:",
        "nombreCompania": "Company name: Villa y Seco Asesores S. L.",
        "direccionCompania": "Address: C / Pasaje de Tetuán nº 13 - 1H, 35212 Telde",
        "emailCompania": "Email: info@vysasesores.com",
        "cifCompania": "C.I.F. B-76325612",
        "registroCompania": "Registry data: Company registered in the Mercantile Registry of Las Palmas Volume 2201, Folio 38, Page GC-54610",
        "tituloPoliticaUso": "USE POLICY",
        "objetoTitulo": "OBJECT",
        "primerParrafoPoliticaUso": "These General Conditions regulate the use and access of the website www.vysasesores.com including the content and services made available on this website. Any person who accesses the website www.vysasesores.com agrees to be bound by the General Conditions in force at the time they access.",
        "segundoParrafoPoliticaUso": "The news and content that Villa y Seco Asesores S.L. makes available to the User on the website VYSASESORES.COM may also be regulated by particular conditions that, in case of conflict, will prevail over the General Conditions. The User, just by accessing this website, is submitting to these General conditions, so he must read them carefully, and in case of doubt consult the scope by any of the means indicated in the CONTACT section",
        "tercerParrafoPoliticaUso": "In any case, Villa y Seco Asesores S. L. reserves the right to modify, at any time and without prior notice, the presentation and configuration of the website, as well as these General Conditions.",
        "piePoliticaUso": "Rates: The description of the Services offered in each area is not detailed as it is for informational purposes only, without therefore establishing any type of commercial relationship through the Villa y Seco Asesores S.L. page.",
        "tituloUsoPaginaWeb": "USE OF THE VYSASESORES.COM WEBSITE",
        "primerParrafoUsoPaginaWeb": "The contents of the VYSASESORES.COM website make the services known, but contracting through the website is not contemplated. Unless otherwise provided in the Particular Conditions, the use of the VYSASESORES.COM website will be free, without prejudice to the cost of connection through the corresponding telecommunications network that the User has access to.",
        "segundoParrafoUsoPaginaWeb": "The User is aware and voluntarily and expressly accepts that the use of the VYSASESORES.COM website is carried out in any case under their sole and exclusive responsibility.",
        "tercerParrafoUsoPaginaWeb": "When using the VYSASESORES.COM website, the User undertakes not to carry out any conduct that could damage the image, interests and rights of VYSASESORES or third parties or that could damage, disable or overload the VYSASESORES.COM website, or that would prevent, in any way, the normal use of the VYSASESORES.COM website.",
        "cuartoParrafoUsoPaginaWeb": "VYSASESORES.COM adopts reasonably adequate security measures to detect the existence of viruses. However, the User must be aware that the security measures of computer systems on the Internet are not entirely reliable and that, therefore, VYSASESORES.COM cannot guarantee the absence of viruses or other elements that may cause alterations in the systems computer (software and hardware) of the User or in their electronic documents and files contained therein.",
        "tituloContenido": "CONTENTS",
        "primeroParrafoContenido": "The Contents of the VYSASESORES.COM website are made available to the User with information from both their own sources and those of third parties, trying to ensure that they are of the highest possible quality and are reasonably updated, but it does not guarantee the usefulness, accuracy, completeness, relevance and / or timeliness of the contents and downloads.",
        "segundoParrafoContenido": "The Contents of the VYSASESORES.COM website does not in any way constitute the provision of an advisory service of any kind. The User understands and accepts that each case or specific case is subject to an infinity of variables that may imply the application of different solutions.",
        "tercerParrafoContenido": "That is why the contents of the VYSASESORES.COM web cannot be considered sufficient or exact to make an adequate decision and admitted by law, and the User is discouraged from making decisions based on the information collected in the Contents without obtaining personalized professional advice.",
        "tituloDerechosIntelectuales": "INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS",
        "contenidoCabeceraDerechosIntelectuales": "Through these General Conditions, no intellectual or industrial property rights are granted on the VYSASESORES.COM website or on any of its component elements, however the User is allowed to reproduce, extract, reuse, forward or use any nature for use. own, being prohibited its plagiarism and commercialization, and in any case holding the user fully responsible for the consequences of its free use.",
        "tituloHypervinculosYSitios": "Hyperlinks and Linked Sites",
        "tituloHypervinculo": "Hyperlinks",
        "primerParrafoHypervinculo": "The User and, in general, any natural or legal person that intends to establish a hyperlink or technical link device (for example, links or buttons) from its website to the VYSASESORES.COM website (the 'Hyperlink') must obtain a prior written authorization from the owner of the website.",
        "segundoParrafoHypervinculo": "The establishment of the Hyperlink does not imply in any case the existence of relations between VILLA Y SECO ASESORES SL and the owner of the web or of the web page in which the Hyperlink is established, nor the acceptance or approval by VILLA Y SECO ASESORES SL of its contents or services.",
        "tercerParrafoHypervinculo": "In any case, VILLA Y SECO ASESORES S. L. reserves the right to prohibit or disable any hyperlink to its website at any time, especially in cases of illegality of the activity or content of the website in which the hyperlink is included.",
        "tituloSitiosVinculados": "Linked Sites",
        "primerParrafoSitiosVinculados": "The VYSASESORES.COM website makes available to the User, only for downloading, hyperlinks or technical link devices (for example, links or buttons), which allow the User access to sites or Internet portals belonging to or managed by third parties (the 'Linked Sites').",
        "segundoParrafoSitiosVinculados": "VYSASESORES.COM does not offer or market by itself or through third parties the information, content and services available on the Linked Sites, nor does it approve, supervise or control in any way the content and services and any material of any nature existing in them, the User assuming exclusively all responsibility for browsing through them.",
        "tituloJurisdiccion": "APPLICABLE LAW AND JURISDICTION",
        "contentidoCabeceraJurisdiccion": "These General Conditions are governed by Spanish Law, and especially by Law 34/2002, of July 11, on information society services and electronic commerce.",
        "tituloDatosPersonales": "INFORMATION ON PROTECTION OF PERSONAL DATA",
        "tituloSegundoDatosPersonales": "IS YOUR PERSONAL DATA RECORDED BY VISITING THE VILLA Y SECO ASESORES S. L. WEB?",
        "contenidoDatosPersonales": "By the mere fact of visiting the VYSASESORES.COM website, no personal data that identifies a User is automatically registered. However, there is certain non-personal and non-identifiable information with a specific User that could be collected and recorded on the Internet servers of VYSASESORES.COM (for example, type of Internet browser of the User, operating system of the User, address IP from which the web pages of the VYSASESORES.COM web are accessed) in order to improve the User's navigation and the management of the web page.",
        "tituloTerceroDatosPersonales": "DO THE SERVICES REQUESTED BY THE WEB COLLECT PERSONAL DATA?",
        "primerParrafoTerceroDatosPersonales": "The company VILLA Y SECO ASESORES does not offer the user the possibility of ordering services through the web. However, on the web, at the disposal of users, several CONTACT forms, for whose reply the following personal data is required:",
        "emailYNombreTerceroDatosPersonales": "E-mail Address and Name",
        "segundoParrafoTerceroDatosPersonales": "It is reported that the treatment of the data obtained through this website implies a low level of risks, since the data processing carried out by VILLA Y SECO ASESORES S. L. is only for contact with the user. The purpose for which the data is collected is to answer your query or suggestion and it will only be kept for the time strictly necessary. At any time, the User has the right to access the data that VILLA Y SECO ASESORES SL has about him, and to request such information in writing, to demand the modification of that which is inaccurate, to delete any that is not necessary, limit it and even to delete data that can be deleted.",
        "terceroParrafoTerceroDatosPersonales": "All VILLA Y SECO ASESORES S. L. staff have received training in information security, data protection and confidentiality at a general level and at a specific level of the advisory service in the commercial, labor, tax and accounting areas. VILLA Y SECO ASESORES S. L. has implemented the appropriate technical and organizational measures to guarantee a level of security appropriate to the risk. These measures have been implemented taking into account the state of the art, the costs of application, and the nature, scope, context and purposes of the treatment, as well as risks of variable probability and severity for the rights and freedoms of people. physical. All the measures are known by the employees, who have signed a commitment to comply with the measures implemented in the company in terms of security and data protection.",
        "cuartoParrafoTerceroDatosPersonales": "This is done under the regulatory parameters of the European Regulation for the Protection of Personal Data Regulation (EU) 2016/679 of the European Parliament and of the Council, of April 27, 2016, regarding the protection of natural persons with regard to treatment of personal data and the free circulation of these data and by which Directive 95/46 / CE (General Data Protection Regulation) is hereby repealed. RGPD.",
        "quintoParrafoTerceroDatosPersonales": "In the event of an incident related to the data collected by the web, the Spanish Agency for Data Protection will be immediately informed. VILLA Y SECO ASESORES S.L. does not transfer the data to third parties, except for those transfers legally imposed by public administrations. If you have a complaint about the treatment we have made of your personal data, you can contact us. You also have the right to file a claim with the Spanish Agency for Data Protection",
        "sextoParrafoTerceroDatosPersonales": "You can exercise your right of access, rectification, deletion of data or withdraw consent at any time, without affecting the legality of the treatment based on the consent prior to its withdrawal, by contacting VILLA Y SECO ASESORES S. L .:",
        "porEmailDatosPersonales": "By email to info@vysasesores.com",
        "porEscritoDatosPersonales": "In writing, by addressing VILLA Y SECO ASESORES S. L C / Pasaje de Tetuán nº 13 - 1º H, (35212 Telde) in Gran Canaria"
    }
};

function cambiarIdioma(lang) {
    var elems = document.querySelectorAll('[data-tr]');
    for (var x = 0; x < elems.length; x++) {
        elems[x].innerHTML = frases.hasOwnProperty(lang)
            ? frases[lang][elems[x].dataset.tr]
            : elems[x].dataset.tr;
    }
}

window.onload = function () {
    cambiarIdioma('es');
    document
        .getElementById('btn-es')
        .addEventListener('click', cambiarIdioma.bind(null, 'es'));
    document
        .getElementById('btn-en')
        .addEventListener('click', cambiarIdioma.bind(null, 'en'));
    document
        .getElementById('btn-de')
        .addEventListener('click', cambiarIdioma.bind(null, 'de'));
}