$(document).ready(function () {
    $("#sms").click(function () {
        var telSMS = $("#telSMS").val();
        var txtSMS = $("#txtSMS").val();
        $.ajax({
            url: 'http://www.vysasesores.com:80/sms.php',
            type: "GET",
            data: { paramTel: telSMS, paramTxt: txtSMS },
            context: document.body,
            success: function (response) {
               $('#avisoSmsResultado').css('display', '');
                setTimeout(
                    function () {
                        $("#cerrarSMS").click();
                    }, 3000);
            }
        });
    });
});