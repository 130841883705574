$(document).ready(function () {
    var url = 'https://www.agenciatributaria.es/AEAT.internet/Inicio/RSS/Todas_las_Novedades/Todas_las_Novedades.xml';
    $.ajax({
        type: 'GET',
        url: "https://api.rss2json.com/v1/api.json?rss_url=" + url,
        dataType: 'jsonp',
        success: function (data) {
            $.each(data.items, function (i, item) {
                $("#feed").append('<div class="card"><div class="card-body"><b>' + item.title + '</b><span>' + item.content + '</span></div></div>');
            });
        }
    });
});