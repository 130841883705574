import 'bootstrap';
import "./translate.js";
import "./import-jquery";
import "./rss.js";
import "./sms.js";
import "./map.js";
import "jquery-ui-dist/jquery-ui.js";

/* Menú con subopciones */

$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');


    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
        $('.dropdown-submenu .show').removeClass("show");
    });


    return false;
});

